html, body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --red-brand: rgb(255, 22, 22);
  --light-gray-text: #d0cfce;
}

@-moz-document url-prefix() { body { font-size: 100%; } } 

.cursor-pointer {
  cursor:pointer;
}

.headline-text {
  font-family:'Unbounded','Inter','Helvetica','Arial';
}

.subheadline-text {
  font-family:'Open Sans','Inter','Helvetica','Arial';
}

.subheadline-text-mono {
  font-family:'NBInternationalPro-Mono','Helvetica','Arial';
}

.code-text {
  font-family:'NBInternationalPro-Mono','Helvetica','Arial';
}

.code-text2 {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global Page Width */
.page-width {
  position:relative;
  margin-left:7.5%;
  margin-right:7.5%;
  width:85%;
}

.page-width-wide {
  position:relative;
  margin-left:0.5%;
  margin-right:0.5%;
  width:99%;
}

.page-main-body {
  margin-top:30px;
  padding-bottom:50px;
}

.page-clean-body {
  margin-top:0px;
  padding-bottom:0px;
}

.clean-row {
  position:relative;
  margin:0px;
  padding:0px;
}

/* Global Header */
.top-nav-header-wide {
  position:relative;
  font-size:18px;
  padding-top:15px;
  border-radius:10px;
  margin-bottom:0px;
}

.top-nav-header {
  position:relative;
  font-size:18px;
  margin-top:0px;
  border-radius:10px;
  margin-bottom:0px;
  z-index:1000;
}

.top-nav-header-full {
  position:relative;
  font-size:14px;
  padding-top:0px;
  border-radius:10px;
  padding-bottom:5px;
  padding-top:7px;
  margin-bottom:0px;
}

.top-nav-container {
  position:relative;
  font-size:18px;
  margin-left:10%;
  margin-right:10%;
  width:80%;
  margin-top:0px;
  border-radius:10px;
  padding:10px;
}

.top-nav-border {
  border:1px solid white;
  position:absolute;
  top:5px;
  left:0;
  z-index:-1;
  width:100%;
  height:50px;
  border-radius:5px;
  transform: skew(-20deg);
}

.top-nav-left-logo {
  float:left;
  width:50%;
  margin-top:7px;
  text-align:left;
}

.top-nav-left-logo-full {
  float:left;
  margin-top:7px;
  text-align:left;
  margin-left:10px;
  position:relative !important;
}

.top-nav-header-left-section {
  float:left;
  margin-top:15px;
  text-align:left;
}

.top-nav-header-left-item {
  float:left;
  height:35px;
  font-size:14px;
  margin-left:25px;
}

.top-nav-header-item-badge {
  float:left;
  margin-left:7px;
  border-radius:5px;
  font-size:10px;
  padding:1px 5px;
  height:18px;
  line-height:18px;
  margin-top:11px
}

.top-nav-header-right-section {
  float:left;
  width:50%;
  margin-top:15px;
  text-align:left;
}

.top-nav-header-right-section-full {
  float:left;
  margin-top:15px;
  text-align:left;
}

.top-nav-header-right-item {
  float:right;
  height:35px;
  font-size:18px;
  margin-left:25px;
}

.top-nav-header-right-item-full {
  float:right;
  height:35px;
  font-size:14px;
  margin-right:25px;
}

.top-nav-header-right-item-text {
  float:left;
  margin-top:7px;
  margin-left:5px;
}

.top-nav-header-right-item-icon {
  float:left;
  height:35px;
}

.top-nav-header-item-text {
  float:left;
  margin-top:10px;
  margin-left:5px;
}

.top-nav-header-item-icon {
  float:left;
  height:35px;
}

.top-nav-header-item-bubble {
  float:left;
  margin-top:16px;
  margin-left:5px;
  margin-right:5px;
}

.top-nav-header-right-profile-icon {
  float:left;
  height:37px;
  width:37px;
  border-radius:18.5px;
  overflow:hidden;
  position:relative;
  border:1px solid transparent;
}

.top-nav-left-button {
  text-align:left;
  padding-left:20px;
  margin-top:7px;
}

.top-nav-middle-text {
  text-align:center;
  margin-top:7px;
}

.top-nav-right-button {
  font-size:18px;
  padding:0px;
  margin-top:2px;
  text-align:left;
}

.top-nav-right-chevron {
  float:right;
  font-size:30px;
}

.top-nav-left-button {
  font-size:18px;
  padding:0px;
  margin-top:1px;
  text-align:left;
}

.top-nav-left-chevron {
  float:left;
  font-size:30px;
}

.top-nav-mobile-container {
  float:left;
  width:100%;
  margin-top:20px;
  text-align:center;
}

.top-nav-mobile-space {
  position:absolute;
  top:10;
  left:10;
  z-index:0;
}

.top-nav-mobile-middle-text {
  text-align:center;
  margin-top:5px;
}

/* Sub Navigation */
.border-section-full {
  position:relative;
  font-size:14px;
  border-radius:10px;
  padding:0px;
  margin:0px;
  margin-top:5px;
  margin-bottom:5px;
}

.divider-thin {
  height:1px;
  width:100%;
  background:white;
}

.divider-thick {
  height:2px;
  width:100%;
  background:white;
}

.corner-button {
  padding:10px;
  padding-top:12px;
  padding-bottom:8px;
  border:2px solid white;
  border-radius:30px;
}

.corner-button:hover {
  color:#FF0000;
  border-color:#FF0000;
  cursor:pointer;
}

.side-nav-button-section {
  width:100%;
  margin-top:0px;
  margin-bottom:0px;
  position:relative;
}

.side-nav-button {
  width:100%;
  margin-top:5px;
  border-radius:5px;
  height:50px;
  vertical-align:middle;
  background:#3A3A3A !important;
  color:white;
  font-family: 'NBInternationalPro-Light','Roboto', Helvetica !important;
}

.side-nav-button:hover {
  width:100%;
  margin-top:5px;
  height:50px;
  vertical-align:middle;
  background:white !important;
  color:black !important;
}

.side-nav-button-active {
  width:100%;
}

.side-nav-button-active .bubble-button{
  background:white !important;
  border-color:white;
}

.side-nav-button-active:hover .bubble-button{
  background:#3A3A3A !important;
  border-color:#3A3A3A;
}

.side-nav-button-text {
  margin-top:2px;
  font-size:14px;
  text-transform: none !important;
}

.side-nav-button-bubble {
  margin-top:14px;
  font-size:14px;
  text-transform: none !important;
}


.side-nav-button-red {
  width:100%;
  margin-top:5px;
  border-radius:5px;
  height:50px;
  vertical-align:middle;
  background:#FF0000 !important;
  color:white;
  font-family: 'NBInternationalPro-Light','Roboto', Helvetica !important;
}

.side-nav-button-red:hover {
  width:100%;
  margin-top:5px;
  height:50px;
  vertical-align:middle;
  background:white !important;
  color:black !important;
}

.side-nav-button-border {
  width:100%;
  margin-top:5px;
  border-radius:5px;
  height:40px;
  vertical-align:middle;
  border:1px solid #3A3A3A !important;
  color:white;
  background:transparent !important;
  font-family: 'NBInternationalPro-Light','Roboto', Helvetica !important;
}

.side-nav-button-border:hover {
  width:100%;
  margin-top:5px;
  height:40px;
  vertical-align:middle;
  background:white !important;
  color:black !important;
}

.round-border-button {
  border-radius:25px;
  color:white;
  padding:5px 10px 5px 10px;
  line-height: 1.25 !important;
  border:1px solid white;
  cursor:pointer;
  width:auto;
  height:auto;
  font-family:'EurostileExt','Helvetica','Arial';
  font-size:14px;
  display:block;
  overflow:hidden;
  height: calc(1em + (1em/1.75));
}

.round-border-button:hover {
  color:#FF0000;
  border:1px solid #FF0000;
}

.round-border-button-green {
  border-radius:25px;
  color:#AAE167;
  padding:5px 10px 5px 10px;
  line-height: 1.25 !important;
  border:1px solid #AAE167;
  width:auto;
  height:auto;
  font-family:'EurostileExt','Helvetica','Arial';
  font-size:14px;
  display:block;
  overflow:hidden;
  height: calc(1em + (1em/1.75));
}

.round-border-button-yellow {
  border-radius:25px;
  color:#FFF367;
  padding:5px 10px 5px 10px;
  line-height: 1.25 !important;
  border:1px solid #FFF367;
  width:auto;
  height:auto;
  font-family:'EurostileExt','Helvetica','Arial';
  font-size:14px;
  display:block;
  overflow:hidden;
  height: calc(1em + (1em/1.75));
}


.round-border-button-grey {
  border-radius:25px;
  color:#606060;
  padding:5px 10px 5px 10px;
  line-height: 1.25 !important;
  border:1px solid #606060;
  width:auto;
  height:auto;
  font-family:'EurostileExt','Helvetica','Arial';
  font-size:14px;
  display:block;
  overflow:hidden;
  height: calc(1em + (1em/1.75));
}

.round-border-button-whitepink {
  color:white !important;
}

.round-border-button-whitepink:hover {
  color:#E30079 !important;
  border:1px solid #E30079 !important;
}

.round-border-button-whitebaseblue {
  color:white !important;
}

.round-border-button-whitebaseblue:hover {
  color:#3773f5 !important;
  border:1px solid #3773f5 !important;
}

.round-border-button-whitegreen {
  color:white !important;
}

.round-border-button-whitegreen:hover {
  color:#AAE167 !important;
  border:1px solid #AAE167 !important;
}


.round-close-button {
  border-radius:14px;
  color:white;
  padding:5px 5px 7px 5px;
  line-height: 1.25 !important;
  border:1px solid white;
  cursor:pointer;
  width:auto;
  height:auto;
  font-size:14px;
  font-family:'EurostileExt','Helvetica','Arial';
  overflow:hidden;
  height: calc(1em + (1em/1.75));
  position:absolute;
  right:0px;
  top:-10px;
}

.round-close-button:hover {
  color:#FF0000;
  border:1px solid #FF0000;
}

.navtoggle {
  color:white;
  background:transparent;
  text-transform:none !important;
  font-family:'NBInternationalPro-Light','Helvetica','Arial' !important;
  padding:0px 10px !important;
  height:30px;
  line-height: 30px;
}

.navtogglefilled {
  color:white;
  background:#606060 !important;
  /* border:2px solid white; */
}

/* Section Titles - With Underlines */
.section-title-text {
  width:100%;
  font-size:18px;
  font-weight:900;
  color:white;
}

.section-title-text-medium {
  width:100%;
  font-size:16px;
  font-weight:900;
  color:white;
}

.section-title-underline {
  width:100%;
  border-bottom:2px solid white;
  height:10px;
}

.section-title-underline-cap {
  width:10%;
  height:10px;
  float:right;
  background:white;
}

/* Input Fields */
.grey-bubble-input {
  border:1px solid #3A3A3A !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
}

.grey-bubble-input:focus {
  border:1px solid #3A3A3A !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.grey-bubble-input:active {
  border:1px solid #3A3A3A !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.grey-bubble-input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  border: thin solid grey;
  border-radius: 3px;
  border:1px solid #3A3A3A !important;
  color:white !important;
  font-size:14px !important;
  background:#606060;
  height: auto !important;
  line-height: 1.5 !important;
  padding:5px 10px;
  cursor:pointer;
  margin-left:-10px;
  margin-right:10px;
}

.light-grey-bubble-input {
  border:1px solid #606060 !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
}

.light-grey-bubble-input:focus {
  border:1px solid #606060 !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.light-grey-bubble-input:active {
  border:1px solid #606060 !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.light-grey-bubble-input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  border: thin solid grey;
  border-radius: 3px;
  border:1px solid #606060 !important;
  color:white !important;
  font-size:14px !important;
  background:#606060;
  height: auto !important;
  line-height: 1.5 !important;
  padding:5px 10px;
  cursor:pointer;
  margin-left:-10px;
  margin-right:10px;
}


.white-bubble-input {
  border:1px solid white !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
}

.white-bubble-input:focus {
  border:1px solid white !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.white-bubble-input:active {
  border:1px solid white !important;
  color:white !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
  outline:none;
}

.white-bubble-input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  border: thin solid white;
  border-radius: 3px;
  border:1px solid white !important;
  color:black !important;
  font-size:14px !important;
  background:white;
  height: auto !important;
  line-height: 1.5 !important;
  padding:5px 10px;
  cursor:pointer;
  margin-left:-10px;
  margin-right:10px;
}





.white-bubble-input-dark {
  border:1px solid white !important;
  color:white !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(255,255,255,.1) !important;
}

.white-bubble-input-dark:focus {
  border:1px solid white !important;
  color:white !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(0,0,0,.5) !important;
  outline:none;
}

.white-bubble-input-dark:active {
  border:1px solid white !important;
  color:white !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(0,0,0,.5);
  outline:none;
}


.black-bubble-input-dark {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75) !important;
}

.black-bubble-input-dark:focus {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none !important;
  background:rgb(255,255,255,.75) !important;
  outline:none;
}

.black-bubble-input-dark:active {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:10px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75);
  outline:none;
}

.black-bubble-input-round {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75) !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.black-bubble-input-round:focus {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none !important;
  background:rgb(255,255,255,.75) !important;
  outline:none;
}

.black-bubble-input-round:active {
  border:1px solid black !important;
  color:black !important;
  font-size:20px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75);
  outline:none;
}


.grey-bubble-input-round-small {
  border:1px solid #272727 !important;
  color:black !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75) !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.grey-bubble-input-round-small:focus {
  border:1px solid #272727 !important;
  color:black !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none !important;
  background:rgb(255,255,255,.75) !important;
  outline:none;
}

.grey-bubble-input-rounds-small:active {
  border:1px solid #272727 !important;
  color:black !important;
  font-size:14px !important;
  font-family:'NBInternationalPro-Light','Roboto', Helvetica !important;
  padding-left:15px !important;
  height: auto !important;
  line-height: 2 !important;
  border-radius:25px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75);
  outline:none;
}


.squared-bubble-input-round {
  border:0px solid black !important;
  color:black !important;
  font-size:20px;
  font-family:'Open Sans','Inter','Helvetica','Arial' !important;
  padding:2px 15px !important;
  height: auto !important;
  line-height: 2.2 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75) !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.squared-bubble-input-round:focus {
  border:0px solid black !important;
  color:black !important;
  font-size:20px;
  font-family:'Open Sans','Inter','Helvetica','Arial' !important;
  padding:2px 15px !important;
  height: auto !important;
  line-height: 2.2 !important;
  border-radius:5px !important;
  box-shadow: none !important;
  background:rgb(255,255,255,.75) !important;
  outline:none;
}

.squared-bubble-input-round:active {
  border:0px solid black !important;
  color:black !important;
  font-size:20px;
  font-family:'Open Sans','Inter','Helvetica','Arial' !important;
  padding-left:15px !important;
  height: auto !important;
  padding:2px 15px !important;
  border-radius:5px !important;
  box-shadow: none;
  background:rgb(255,255,255,.75);
  outline:none;
}

/* Chips Typehead */
.chips {
  color:white !important;
  border-bottom: 1px solid white !important;
  box-shadow: none !important;
  font-family:'NBInternationalPro-Light','Helvetica','Arial';
}
.chips:focus {
  border-bottom: 1px solid white !important;
  color:white !important;
}

.chips .input {
  color:white !important;
  font-family:'NBInternationalPro-Light','Helvetica','Arial';
}

.chips .dropdown-content {
  background: #e4e4e4 !important;
  color: black !important;
  margin-top:10px !important;
  top: 40px !important;
}

.autocomplete-content li .highlight {
  color:#3A3A3A !important;
}

.autocomplete-content li{
  color:black !important;
}

.chips .dropdown-content li>a {
  color:black !important;
}
.chips .dropdown-content li>span {
  color:black !important;
}

.chip:focus {
  background-color:#FF0000 !important;
  color:white !important;
}


/* Stats Bars */
.stat-bar-row {
  font-size:14px;
  margin-bottom:5px;
}

.stat-bar-top-text {
  text-align:right;
  font-size:16px;
}

.stat-bar-base-bar {
  border-radius:0px;
  height: 10px;
  margin-top:5px;
  border-bottom: 1px solid white !important;
}

/* Page Gridboxes */
.page-gridbox {
  width:100%;
  height:350px;
  background:black;
  border-radius:10px;
  position:relative;
  overflow:hidden;
}

.page-gridbox-header {
  position:absolute;
  top:7%;
  left:30px;
  font-size:18px;
}

.page-gridbox-info {
  position:absolute;
  top:40%;
  left:30px;
  font-size:18px;
}

.page-gridbox-inforow {
  float:left;
  width:100%;
  margin-bottom:5px;
}

/* Page Modals */
.page-modal {
  border-radius:10px !important;
  color:white !important;
  background-color: #3A3A3A !important;
}

.page-modal-download {
  width:95%;
  max-width:400px !important;
}

.page-modal-right {
  left:unset !important;
  right:20px !important;
  top:20px !important;
}

.page-modal-small {
  max-width:500px !important;
}

.page-modal-smedium {
  max-width:575px !important;
}



.page-modal-medium {
  max-width:700px !important;
}

.page-modal-tall {
  max-height:90vh !important;
}

.page-modal-large {
  width:55% !important;
  max-height: 80vh !important;
}

.page-modal-media {
  width:55% !important;
  max-height: 80vh !important;
  line-height:0 !important;
  border:1px solid #3A3A3A;
  background:black !important;
}

.page-modal-media-vertical {
  max-width: 25% !important;
  width:auto !important;
  max-height: 80vh !important;
  line-height:0 !important;
  border:1px solid #3A3A3A;
  background:black !important;
}

.page-modal-full {
  max-width:100% !important;
  width:98% !important;
  max-height:90vh !important;
}

@media screen and (max-width: 750px) {
  .page-modal-smedium {
    max-width:100% !important;
    width:92% !important;
  }
  .nav-wrapper {
    height:auto !important;
  }
}

@media screen and (max-width: 950px) {
  .page-modal-large {
    max-width:100% !important;
    width:92% !important;
  }

  .page-modal-full {
    max-width:100% !important;
    width:92% !important;
  }

  .page-modal-media {
    max-width:100% !important;
    width:92% !important;
    border:1px solid #EBEBEB;
  }

  .page-modal-media-vertical {
    max-width: 80% !important;
    width:auto !important;
    max-height: 90vh !important;
    line-height:0 !important;
    border:1px solid #3A3A3A;
    background:black !important;
  }
}

/* Color Specific */
.btn-flat2 {
  font-family:'EurostileExt','Helvetica','Arial';
  font-size:18px;
}

.exr-red-bg {
  background-color: #FF0000 !important;
}

.exr-dark-grey-bg {
  background-color: #141414 !important;
}

.exr-light-grey-bg {
  background-color: #d0cfce !important;
}

.exr-light-grey-text {
  color: #d0cfce;
}

.exr-red-text {
  color: #FF0000;
}

.exr-trans-grey-bg {
  background-color:rgb(20,20,20,0.8);
}

.exr-trans-black-bg {
  background-color:rgb(0,0,0,0.8);
}

.exr-trans-black-bg-subtle {
  background-color:rgb(0,0,0,0.25);
}

.exr-new-dark-grey {
  background-color:#272727;
}

.exr-new-light-grey {
  background-color:#3A3A3A;
}

.exr-stats-light-grey {
  background-color:#606060;
}

.exr-stats-light-grey2 {
  background-color:#7a7979;
}

.exr-stats-light-green {
  background-color:#B4DF77;
}

.exr-stats-red {
  background-color:#EB2228;
}

.exr-stats-light-green2 {
  background-color: #AAE167 !important;
}

.exr-light-green {
  background-color: #AAE167 !important;
}

.exr-light-green-text {
  color: #AAE167 !important;
}

.exr-stats-yellow {
  background-color:#FFF367 !important;
}

.exr-yellow-text {
  color:#FFF367 !important;
}

/* Track Meet Racing Accents */

.exr-orange {
  background-color: #EF5C28 !important;
}

.exr-light-orange {
  background-color: #ED752A !important;
}

.exr-orange-text {
  color: #EF5C28 !important;
}

.exr-light-orange-text {
  color: #ED752A !important;
}

/* PINK Racing Accents */

.exr-dark-pink {
  background-color:#E30079 !important;
}

.exr-dark-pink-text {
  color:#E30079 !important;
}

.exr-mid-pink {
  color:#fe0595 !important;
}

.exr-mid-pink-text {
  color:#fe0595 !important;
}

.pink-dark-pink-background {
  background-color:#E30079 !important;
}

.pink-polkadot-background {
  background-color:#E6007A !important;
}

.exr-pink-polkadot-text {
  color:#E6007A !important;
}

.pink-mid-pink-background {
  color:#fe0595 !important;
}

.pink-dark-pink-text {
  color:#E30079 !important;
}

.pink-mid-pink-text {
  color:#fe0595 !important;
}

.exr-pink-text {
  color:hsl(325, 100%, 56%) !important;
}

.pink-pink-background-link {
  background-color:#E30079 !important;
  cursor:pointer;
}

.pink-pink-background-link:hover {
  background-color:#E30079 !important;
}

.pink-grey-background-link {
  background-color:#3A3A3A !important;
  cursor:pointer;
}

.pink-grey-background-link:hover {
  background-color:#E30079 !important;
}

/* BASE Racing Accents */

.exr-base-blue {
  background-color:#3773f5 !important;
}

.exr-base-blue-text {
  color:#3773f5 !important;
}

.exr-base-blue-light-text {
  color:#37AEF5;
}

.base-blue-background {
  background-color:#3773f5 !important;
}

.base-blue-background-link {
  background-color:#3773f5 !important;
  cursor:pointer;
}

.base-blue-background-link:hover {
  background-color:#3773f5 !important;
}

.base-blue-grey-background-link {
  background-color:#3A3A3A !important;
  cursor:pointer;
}

.base-blue-grey-background-link:hover {
  background-color:#3773f5 !important;
}




.exr-dark-grey-trans {
  background: rgb(0,0,0,.5);
}

.exr-scroll-bar-vertical {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: rgb(0,0,0,.75) rgb(0,0,0,.2);
  scrollbar-width: thin;
}

.exr-scroll-bar-vertical::-webkit-scrollbar {
  background-color: rgb(0,0,0,.2);
}

/* Add a thumb */
.exr-scroll-bar-vertical::-webkit-scrollbar-thumb {
  background-color: rgb(0,0,0);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #606060;
  border-radius:5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #EBEBEB;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Buttons */
.bracket-button {
  font-size:20px;
  padding-right:0px;
  padding-left:0px;
}

.bracket-button-bracket {
  font-family:'Roboto';
  font-weight:100;
  padding:0px 3px 0px 3px;
}

.bubble-button {
  width:8px;
  height:8px;
  border-radius:8px;
  border:1px solid white;
}

.white-fill-link {
  cursor:pointer;
}

.white-fill-link .bubble-button {
  background:transparent;
}

.white-fill-link:hover .bubble-button {
  background:white;
}

.white-fill-link-filled .bubble-button {
  background:white !important;
}

.grey-fill-link {
  cursor:pointer;
}

.grey-fill-link .bubble-button {
  background:transparent;
  border-color:white;
}

.grey-fill-link:hover .bubble-button {
  background:#3A3A3A;
  border-color:#3A3A3A;
}

.black-red-link {
  color:black;
}

.black-red-link:hover {
  color: #FF0000;
}

.white-red-link {
  color:white !important;
  cursor:pointer;
}

.white-red-link:hover {
  color: #FF0000 !important;
}

.white-red-link-active {
  color: #FF0000 !important;
  cursor:pointer;
}

.white-green-link {
  color:white !important;
  cursor:pointer;
}

.white-green-link:hover {
  color: #B4DF77 !important;
}

.white-green-link-active {
  color: #B4DF77 !important;
  cursor:pointer;
}

.white-yellow-link {
  color:white !important;
  cursor:pointer;
}

.white-yellow-link:hover {
  color: #FFF367 !important;
}

.white-yellow-link-active {
  color: #FFF367 !important;
  cursor:pointer;
}

.yellow-yellow-link {
  color: #FFF367 !important;
  cursor:pointer;
}

.yellow-yellow-link:hover {
  color: #FFF367 !important;
}

.yellow-yellow-link-active {
  color: #FFF367 !important;
  cursor:pointer;
}

.grey-green-link {
  color:#606060 !important;
  cursor:pointer;
}

.grey-green-link:hover {
  color: #B4DF77 !important;
}

.grey-green-link-active {
  color: #B4DF77 !important;
  cursor:pointer;
}

.green-green-link {
  color: #B4DF77 !important;
}

.green-green-link:hover {
  color: #B4DF77 !important;
}

.green-green-link-active {
  color: #B4DF77 !important;
  cursor:pointer;
}

.white-pink-link {
  color: white !important;
}

.white-pink-link:hover {
  color: #E30079 !important;
}

.white-pink-link-active {
  color: #E30079 !important;
  cursor:pointer;
}

.pink-pink-link {
  color: #E30079 !important;
}

.pink-pink-link:hover {
  color: #E30079 !important;
}

.pink-pink-link-active {
  color: #E30079 !important;
  cursor:pointer;
}

.white-base-blue-link {
  color: white !important;
}

.white-base-blue-link:hover {
  color: #3773f5 !important;
}

.white-base-blue-link-active {
  color: #3773f5 !important;
  cursor:pointer;
}

.base-blue-blue-link {
  color: #3773f5 !important;
}

.base-blue-blue-link:hover {
  color: #3773f5 !important;
}

.base-blue-blue-link-active {
  color: #3773f5 !important;
  cursor:pointer;
}

.white-pink-border-link {
  color: white !important;
  border:1px solid #E30079 !important;
}

.white-green-border-link {
  color: white !important;
  border:1px solid #B4DF77 !important;
}

.white-base-blue-border-link {
  color: white !important;
  border:1px solid #3773f5 !important;
}

.white-red-button {
  background-color:white !important;
  color:black !important;
}

.white-red-button:hover {
  background-color: #FF0000 !important;
  color: white !important;
}

.stats-light-grey-white-link {
  cursor:pointer;
  background-color:#606060 !important;
  color:white !important;
}

.stats-light-grey-white-link:hover {
  cursor:pointer;
  background-color: white !important;
  color: black !important;
}

.stats-light-grey-red-link {
  cursor:pointer;
  background-color:#606060 !important;
  color:white !important;
}

.stats-light-grey-red-link:hover {
  cursor:pointer;
  background-color: #FF0000 !important;
  color: white !important;
}

.stats-trans-grey-link {
  cursor:pointer;
  background-color: transparent !important;
  color:white !important;
}

.stats-trans-grey-link:hover {
  cursor:pointer;
  background-color: #606060  !important;
  color: white !important;
}


.grey-light-grey-button-link {
  background:#3A3A3A;
  border:1px solid #3A3A3A;
  cursor:pointer;
}

.grey-light-grey-button-link:hover {
  background:#606060;
  border:1px solid white;
}

.grey-light-grey-button-link-active {
  background:#606060;
  border:1px solid white;
}

.light-grey-stats-grey-button-link {
  background:#606060;
  border:1px solid #606060;
  cursor:pointer;
}

.light-grey-stats-grey-button-link:hover {
  background:#7a7979;
  border:1px solid white;
}

.light-grey-stats-grey-button-link-active {
  background:#7a7979;
  border:1px solid white;
}

.light-grey-green-button-link {
  background:transparent !important;
  border:1px solid  white;
  cursor:pointer;
}

.light-grey-green-button-link:hover {
  background:transparent !important;
  border:1px solid #B4DF77 !important;
  color:#B4DF77 !important;
}

.light-grey-green-button-link-active {
  background:transparent !important;
  border:1px solid #B4DF77 !important;
  color:#B4DF77 !important;
}



.white-pink-button-link {
  background:transparent !important;
  border:1px solid  white;
  cursor:pointer;
}

.white-pink-button-link:hover {
  background:transparent !important;
  border:1px solid #E30079 !important;
  color:#E30079 !important;
}

.white-pink-button-link-active {
  background:transparent !important;
  border:1px solid #E30079 !important;
  color:#E30079 !important;
}

.white-base-blue-button-link {
  background:transparent !important;
  border:1px solid  white;
  cursor:pointer;
}

.white-base-blue-button-link:hover {
  background:transparent !important;
  border:1px solid #3773f5 !important;
  color:#3773f5 !important;
}

.white-base-blue-button-link-active {
  background:transparent !important;
  border:1px solid #3773f5 !important;
  color:#3773f5 !important;
}

/* PolkadotGo Specific */
.polkadot-purple-background {
  background: #301C71 !important;
}

.polkadot-pink-background {
  background: #E30079 !important;
}

.polkadot-green-background {
  background: #CFF837 !important;
}

.polkadot-pink-text {
  color:#E30079;
}

.polkadot-purple-text {
  color: #301C71;
}

.polkadot-neon-text {
  color:#CFF837;
}

.polkadot-blue-trans-background {
  background: rgba(0,178,254,0.86) !important;
}

.pink-green-button {
  cursor:pointer;
  background:#E30079;
  color:white;
  border-radius:5px;
  padding:3px 15px;
  margin-right:12px;
  font-size:16px;
  font-weight:600;
  font-family:'Open Sans','Inter','Helvetica','Arial';
}

.pink-green-button:hover {
  background:#CFF837;
  color:#301C71;
}

.pink-green-button-active {
  cursor:pointer;
  background:#CFF837;
  color:#301C71;
  border-radius:5px;
  padding:3px 15px;
  margin-right:12px;
  font-size:16px;
  font-weight:600;
  font-family:'Open Sans','Inter','Helvetica','Arial';
}

.white-neon-link {
  cursor:pointer;
  color:white;
  font-weight:600;
  font-family:'Open Sans','Inter','Helvetica','Arial';
}

.white-neon-link:hover {
  color:#CFF837;
}

.white-neon-link-active {
  color:#CFF837;
}

.directory-icon-link {
  cursor:pointer;
  float:right;
  margin-left:10px;
  margin-right:10px
}



/* Tooltip Styling */
.material-tooltip {
  border-radius:10px !important;
  background:#3A3A3A;
  -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

/* Image Specific */
.fill-image-container {
  width:100%;
  height:100%;
}

/* Design Specific */
.cornercutout {
  position:absolute;
  background:black;
  width:10px;
  height:10px;
}

.top-left {
  top:0px;
  left:0px;
}

.top-right {
  top:0px;
  right:0px;
}

.bottom-left {
  bottom:0px;
  left:0px;
}

.bottom-right {
  bottom:0px;
  right:0px;
}


/* SPLASH EVENT */
#main-landing-page {
  margin-bottom:0px;
  margin-top:0px;
  border-top:0px;
}

.cursor-pointer {
  cursor:pointer;
}

#splash-main {
  width:100%;
  position:absolute;
  left:0%;
  top:9%;
  color:white;
  margin-top:25px;
  text-align:center;
}

.splash-logo {
  float:left;
  width:100%;
  text-align:center;
}

.splash-headline {
  float:left;
  width:100%;
  margin-top:50px;
  font-size:18px;
}

.splash-headline {
  float:left;
  width:100%;
  margin-top:50px;
  font-size:18px;
}

.splash-button-bullet-top {
  float:left;
  width:100%;
  margin-top:50px;
  font-size:12px;
}

.splash-button-bullet-bottom {
  float:left;
  width:100%;
  margin-top:-2px;
  font-size:12px;
}

.splash-main-button-wrapper {
  float:left;
  width:100%;
  margin-top:-9px;
  font-size:24px;
}

.splash-main-button {
  font-size:24px;
  padding-right:0px;
  padding-left:0px;
}

.splash-main-button-bracket {
  font-family:'Roboto';
  font-weight:100;
}

.splash-discord-top {
  position:fixed;
  right:30px;
  top:0px;
}

.splash-discord-button-inline {
  float:left;
  width:100%;
  font-size:14px;
  margin-top:30px;
  margin-bottom:30px;
}

.splash-mint-status-text {
  float:left;
  margin-left:10%;
  width:80%;
  font-size:14px;
  margin-top:60px;
}

.splash-modal-title-alt {
  margin-top:5px;
  display:none;
}

.splash-modal-confirmtxt {
  font-size:18px;
  margin-top:25px;
  display:none;
}

.splash-submodal-button {
  margin-top:-15px;
  font-size:12px;
  padding-right:0px;
  padding-left:0px;
}

.splash-modal-button {
  margin-top:-5px;
  font-size:24px;
  padding-right:0px;
  padding-left:0px;
}

.splash-modal-loader {
  margin-top:10px;
  display:none;
}

.firstsection {
  margin-top:80px;
}

.event-register-large-headline {
  font-size:40px;
  line-height:1;
}

.event-register-large-headline-mid {
  font-size:40px;
  line-height:1;
}

.headlinebrow {
  font-size:24px;
}

.event-register-subheadline {
  font-size:24px;
}

.event-register-subheadline-mid {
  font-size:24px;
}

.event-register-subheadline-small {
  float:left;
  font-size:24px;
}

.event-register-formheader {
  font-size:18px;
}

.event-register-formbutton {
  float:left;
  font-size:20px;
  height:42px;
}

.event-register-formbutton2 {
  float:left;
  font-size:20px;
  height:42px;
}

.section-triple-subtext {
  width:90%;
  font-size:18px;
}

.section-line-seperator {
  text-align:left;
  float:left;
  border-width:2px;
}

.overlaymainphoto {
  position:relative;
  padding:0px;
}
.overlaysubinfo {
  height:100px;
  padding:0px;
}

.overlaysubinfolight {
  height:100px;
  padding:0px;
}

.overlaysubinfophoto {
  display:none;
}

.overlaysubinfotext {
  padding:0px;
  margin:0px;
}

.overlaybutton {
  border-radius:5px;
}

.user-current-position {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-bottom:20px !important;
  padding-top:0px !important;
  padding-left:0px;
  padding-right:0px;
  margin-top:-10px;
}

.bifrost-gradient {
  background: linear-gradient(90deg, #004aad, #cb6ce6) !important;
}

.bifrost-light-purple-text {
  color: #a377fa !important;
}

.bifrost-mid-purple-text {
  color: #8c52ff !important;
}

.bifrost-dark-purple-text {
  color: #5e17eb !important;
}

.nviewer-info-carousel-button-pink {
  float:right;
  background-color:white;
  height:10px;
  border-radius:10px;
  width:10px;
  cursor:pointer;
  margin-right:10px;
}

.nviewer-info-carousel-button-pink:hover{
  background-color:#E30079;
}

.nviewer-info-carousel-button-pink:hover{
  background-color:#E30079;
}
.nviewer-info-carousel-button-pink-active{
  background-color:#E30079;
}



.nviewer-info-carousel-button-base-blue {
  float:right;
  background-color:white;
  height:10px;
  border-radius:10px;
  width:10px;
  cursor:pointer;
  margin-right:10px;
}

.nviewer-info-carousel-button-base-blue:hover{
  background-color:#3773f5;
}

.nviewer-info-carousel-button-base-blue:hover{
  background-color:#3773f5;
}
.nviewer-info-carousel-button-base-blue-active{
  background-color:#3773f5;
}

.exr-nviewer-segment {
  background-color:#3A3A3A;
  border-radius:10px;
  padding:20px;
}

.exr-nviewer-divider {
  border:1px solid white;
  width:100%;
  height:1px;
  margin-top:10px;
  margin-bottom:10px;
}

.exr-nviewer-img-large {
  width:100%;
  overflow:hidden;
  background-size:cover !important;
  background-image:'';
  background-position:center;
}

.exr-nviewer-main-faction-icon {
  position:absolute;
  bottom:10px;
  right:10px;
}

.exr-nviewer-main-faction-icon-static {
  margin-top:-15px;
  margin-left:-5px;
  line-height: .6;
}

.exr-nviewer-main-headline {
  font-size:30px;
  width:100%;
  margin-top:15px;
  line-height: 1;
}

.exr-nviewer-main-subheadline {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size:14px;
  margin-top:5px;
}

.exr-nviewer-section-headline {
  font-size:20px;
  width:100%;
  margin-top:20px;

}

.exr-nviewer-main-text {
  font-size:16px;
  width:100%;
}

.divider-thin {
  height:1px;
  width:100%;
  background:white;
}

.exr-selected-grid-box {
  opacity:1 !important;
  cursor:zoom-in !important;
  border-radius:10px;
}

.exr-filled-grid-box {
  opacity:0.25;
  cursor:pointer;
  border-radius:10px;
}

.exr-filled-grid-box:hover {
  opacity:1;
  border-radius:10px;
}

.nviewer-info-carousel-section {
  display:none;
}

.nviewer-attribute-bubble {
  border:1px solid white;
  padding:2px;
  font-size:14px;
  text-align:center;
  margin-bottom:5px;
  border-radius:5px;
}

.nviewer-rarity-num-large {
  font-size:20px;
  padding:2px 10px;
  border-radius:5px;
  border:0px solid white;
  text-align:center;
  margin-right:20px;
}

.nviewer-info-carousel-button {
  float:right;
  background-color:white;
  height:10px;
  border-radius:10px;
  width:10px;
  cursor:pointer;
  margin-right:10px;
}

.nviewer-info-carousel-button:hover{
  background-color:#FF0000;
}

.nviewer-info-carousel-button-white:hover{
  background-color:white;
}

.info-carousel-button-filled-white {
  background-color:white;
}

.info-carousel-button-filled {
  background-color:#FF0000;
}

#exr-nviewer-info-box {
  /* overflow-x:hidden;*/
  scrollbar-color:#3A3A3A #272727; 
}

#exr-nviewer-grid-box {
  background:#272727;
  overflow-x:hidden;
  scrollbar-color:#3A3A3A transparent;
}

/* .body-main::-webkit-scrollbar-track { 
  display:none;
} */

.body-main::-webkit-scrollbar-thumb { 
  background:#3A3A3A;
  border-radius:10px;
}

.simplebar-scrollbar::before {
  background-color:transparent;
}
.simplebar-track {
  background-color:transparent;
}
.simplebar-scrollbar {
  background-color:transparent;
}

.select-wrapper {
  border:1px solid #606060 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
}

.select-wrapper input {
  border:0px solid #606060 !important;
  border-radius:5px !important;
  box-shadow: none;
  background:transparent;
}


.final-results-col {
  margin-bottom:10px;
  padding:10px !important;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  border:0px solid white;
  font-family:helvetica;
  overflow:hidden;
}

.stats-viewer-primary-icon {
  line-height:1;
}

.stats-viewer-headline {
  font-size:32px;
}

.stats-viewer-subheadline {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size:12px;
  margin-top:5px;
}

.stats-viewer-stats-headline {
  margin-top:30px;
  font-size:18px;
  margin-bottom:5px;
}



@media screen and (max-width: 450px) {

  .event-register-subheadline-small {
      font-size:18px !important;
      text-align:center !important;
      margin-top:10px;
  }
}

@media screen and (max-width: 750px) {
  .firstsection {
      margin-top:30px;
  }

  .event-register-large-headline {
      font-size:24px !important;
      text-align:center;
  }

  .event-register-large-headline-mid {
      font-size:30px !important;
      text-align:center !important;
      line-height:1.1;
  }

  .headlinebrow {
      font-size:18px !important;
  }

  .event-register-subheadline {
      font-size:18px !important;
      text-align:center;
      margin-top:10px;
  }

  .event-register-subheadline-mid {
      font-size:18px !important;
      text-align:center !important;
      margin-top:10px;
  }
  

  .event-register-formheader {
      font-size:14px;
  }
  
  .event-register-formbutton {
      float:right;
      margin-right:-10px;
      font-size:14px;
      height:34px;
  }

  .event-register-round-button {
      padding:20px;
  }

  .main-sponsor-icons {
      text-align:center;
      margin-bottom:10px;
  }

  .section-triple-subtext {
      width:100%;
      font-size:14px;
      margin-bottom:30px;
  }

  
}

@media screen and (max-width: 950px) {

  .firstsection {
      margin-top:50px;
  }
  .event-register-large-headline {
      font-size:30px;
      text-align:center;
      line-height:1.1;
  }

  .event-register-large-headline-mid {
      font-size:30px;
      text-align:left;
      line-height:1.1;
  }

  .headlinebrow {
      font-size:20px;
  }

  .event-register-subheadline {
      font-size:20px;
      text-align:center;
      margin-top:10px;
  }

  .event-register-subheadline-mid {
      font-size:20px;
      margin-top:10px;
      text-align:left;
  }

  .event-register-formheader {
      font-size:16px;
  }

  .section-triple-subtext {
      width:100%;
      font-size:16px;
      margin-bottom:30px;
  }

  .section-line-seperator {
      width:80% !important;
      margin-left:10%;
      border-width:1px;
  }

  .overlaysubinfo {
      background:#272727;
      border-radius:5px;
      margin-top:-15px;
      margin-bottom:0px;
  }

  .overlaysubinfolight {
      background:#EBEBEB;
      border-radius:5px;
      margin-top:-15px;
      margin-bottom:0px;
  }

  .overlaysubinfophoto {
      display:block;
  }

  .overlaysubinfotext {
      padding:10px;
  }

  .overlaymainphoto {
      display:none;
  }

  
}
